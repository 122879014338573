import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Box } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages${routeItem.componentPath}`))}
            />
          );
        })}
      </Switch>
      <Footer />
      <Box style={{width:"100vw",display:"flex",justifyContent:'center'}}><CopyrightIcon/>2025 Clearcase Technology Solutions Private Limited</Box>
    </Suspense>
  );
};

export default Router;
